/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import SportsHandballIcon from '@mui/icons-material/SportsHandball';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import CalculateIcon from '@mui/icons-material/Calculate';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';


const mock = [
  {
    title: 'Multiple Sports Leagues',
    subtitle:
      'NHL, MLB, NCAAF, NCAAB, NFL, More to come...',
    icon: <SportsFootballIcon />
  },
  {
    title: 'Game Betting Markets',
    subtitle:
      'Moneylines, Spreads, and Totals.',
    icon: <CalculateIcon />
  },
  {
    title: 'Player Props',
    subtitle:
      'Built in-house with machine learning.',
    icon: <SportsHandballIcon />
  },
  {
    title: 'Positive Expected Value',
    subtitle:
      'Each market showcases which bets have a edge on Vegas.',
    icon: <ThumbUpIcon />
  },
  {
    title: 'Fresh Sportsbook Odds',
    subtitle: 'The odds for each bet type update multiple times an hour.',
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
        />
      </svg>
    ),
  },
  {
    title: 'Relentless Support',
    subtitle:
      'How can we make your experience better?',
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
        />
      </svg>
    ),
  },
];

const Benefits = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          align={'center'}
          gutterBottom
          sx={{ fontWeight: 700 }}
        >
          Positive Expected Value Betting = Long Term Success
        </Typography>
        <Typography
          variant="h6"
          align={'center'}
          color={'text.secondary'}
        >
          Here's how we help you win, and keep your significant other happy.
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <Box
              component={Card}
              padding={4}
              borderRadius={2}
              width={1}
              height={1}
              data-aos={'fade-up'}
              data-aos-delay={i * 100}
            >
              <Box display={'flex'} flexDirection={'column'}>
                <Box
                  component={Avatar}
                  width={50}
                  height={50}
                  marginBottom={2}
                  bgcolor={theme.palette.primary.main}
                  color={theme.palette.background.paper}
                >
                  {item.icon}
                </Box>
                <Typography variant={'h6'} gutterBottom sx={{ fontWeight: 500 }}>
                  {item.title}
                </Typography>
                <Typography color="text.secondary">{item.subtitle}</Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Benefits;
