import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from 'components/Container';
import Main from 'layouts/Main';
import { renderFormattedTime, renderFormattedDate, groupLogs, formatTimeAgo, groupLogsByPitcher } from 'utils/utils';
import { Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow  } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const MLBprops = () => {
  const [logs, setLogs] = useState([]);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const typographyVariant = isMd ? 'body2' : 'subtitle1';

  useEffect(() => {
    fetch('/api/mlbxkvalueslog')
      .then(res => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then(data => {
        console.log(data);
        data.sort((a, b) => new Date(a.commence_time) - new Date(b.commence_time));
        setLogs(data);  // Update state with the fetched and sorted data
      })
      .catch(error => console.error('Error:', error));
  }, []); // Empty dependence

  const groupedLogs = groupLogs(logs);
  const firstGroup = groupedLogs[0];
  // const firstBookmaker = firstGroup && firstGroup[0] ? firstGroup[0].bookmaker : null;
  // const secondGroup = groupedLogs[1];
  // const secondBookmaker = secondGroup && secondGroup[1] ? secondGroup[1].bookmaker : null;
  const timeSinceUpdate = firstGroup && firstGroup[0] ? formatTimeAgo(firstGroup[0].last_api_update) : null;
  const headerStyling = { align: 'right', style: { verticalAlign: 'top' } };

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <Main bgcolor={'background.paper'}>
        <Container>
          <div>
            <header>
              <h1>Upcoming MLB Strikeout Props</h1>
              <p><i>{timeSinceUpdate}Lines refresh every 4 hours</i></p>
              <p>xK is produced by a proprietary machine learning model (xgboost). More info coming.</p>
              <p>Expected Value (EV) calculated by converting the sportsbook's line and comparing to the predicted strikeout number (xK) by way of a Poisson Distribution. Numbers in green suggest positive expected value from a betting perspective, these are +EV Bets.</p>
            </header>
          </div>
          {logs.length === 0 ? (
            <div><b>Please check back soon, currently no lines are available.</b></div>
          ) : (
            <TableContainer component={Paper} sx={{ mx: 'auto', maxWidth: 950 }}>
              <Table sx={{ minWidth: 400}} aria-label="mlb-total-table">
                <TableHead sx={{ bgcolor: 'grey.200' }}>
                  <TableRow>
                    <TableCell align='left' style={{ verticalAlign: 'top' }}><strong>Player</strong></TableCell> 
                    <TableCell {...headerStyling}><strong>Team</strong></TableCell>
                    <TableCell {...headerStyling}><strong>Opponent</strong></TableCell>
                    <TableCell {...headerStyling}><strong>xK</strong></TableCell>
                    <TableCell {...headerStyling}><strong>DraftKings</strong></TableCell>
                    <TableCell {...headerStyling}><strong>DraftKings<br />EV</strong></TableCell>
                    <TableCell {...headerStyling}><strong>FanDuel</strong></TableCell>
                    <TableCell {...headerStyling}><strong>FanDuel<br />EV</strong></TableCell>
                    <TableCell {...headerStyling}><strong>First Pitch</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupLogsByPitcher(logs).map((gameLogs, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <Typography variant={typographyVariant}>{gameLogs[0]?.player}</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant={typographyVariant}>{gameLogs[0]?.pitcher_team || gameLogs[1]?.pitcher_team}</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant={typographyVariant}>{gameLogs[0]?.hitting_team || gameLogs[1]?.hitting_team}</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant={typographyVariant}>{Math.round((gameLogs[0]?.xk || gameLogs[1]?.xk) * 10) / 10}</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant={typographyVariant}>
                          {gameLogs[0].prop_point !== undefined && gameLogs[0].prop_point !== '--' ? `o${gameLogs[0].prop_point}` : gameLogs[0].prop_point} 
                          {gameLogs[0].over_price !== '--' ? (gameLogs[0].over_price > 0 ? '+' : '') + gameLogs[0].over_price : gameLogs[0].over_price}
                        </Typography>
                        <Typography variant={typographyVariant}>
                          {gameLogs[0].prop_point !== undefined && gameLogs[0].prop_point !== '--' ? `u${gameLogs[0].prop_point}` : gameLogs[0].prop_point} 
                          {gameLogs[0].under_price !== '--' ? (gameLogs[0].under_price > 0 ? '+' : '') + gameLogs[0].under_price : gameLogs[0].under_price}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant={typographyVariant} style={{ color: gameLogs[0].over_diff * 100 >= 2 ? 'green' : 'inherit', fontWeight: gameLogs[0].over_diff * 100 >= 2 ? 'bold' : 'normal' }}>{gameLogs[0].over_diff !== '--' ? `${Math.round(gameLogs[0].over_diff * 1000)/10}%` : gameLogs[0].over_diff}</Typography>
                        <Typography variant={typographyVariant} style={{ color: gameLogs[0].under_diff * 100 >= 2 ? 'green' : 'inherit', fontWeight: gameLogs[0].under_diff * 100 >= 2 ? 'bold' : 'normal' }}>{gameLogs[0].under_diff !== '--' ? `${Math.round(gameLogs[0].under_diff * 1000)/10}%` : gameLogs[0].under_diff}</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant={typographyVariant}>
                          {gameLogs[1].prop_point !== undefined && gameLogs[1].prop_point !== '--' ? `o${gameLogs[1].prop_point}` : gameLogs[1].prop_point} 
                          {gameLogs[1].over_price !== '--' ? (gameLogs[1].over_price > 0 ? '+' : '') + gameLogs[1].over_price : gameLogs[1].over_price}
                        </Typography>
                        <Typography variant={typographyVariant}>
                          {gameLogs[1].prop_point !== undefined && gameLogs[1].prop_point !== '--' ? `u${gameLogs[1].prop_point}` : gameLogs[1].prop_point} 
                          {gameLogs[1].under_price !== '--' ? (gameLogs[1].under_price > 0 ? '+' : '') + gameLogs[1].under_price : gameLogs[1].under_price}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant={typographyVariant} style={{ color: gameLogs[1].over_diff * 100 >= 2 ? 'green' : 'inherit', fontWeight: gameLogs[1].over_diff * 100 >= 2 ? 'bold' : 'normal' }}>{gameLogs[1].over_diff !== '--' ? `${Math.round(gameLogs[1].over_diff * 1000)/10}%` : gameLogs[1].over_diff}</Typography>
                        <Typography variant={typographyVariant} style={{ color: gameLogs[1].under_diff * 100 >= 2 ? 'green' : 'inherit', fontWeight: gameLogs[1].under_diff * 100 >= 2 ? 'bold' : 'normal' }}>{gameLogs[1].under_diff !== '--' ? `${Math.round(gameLogs[1].under_diff * 1000)/10}%` : gameLogs[1].under_diff}</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant={typographyVariant}> {renderFormattedDate(gameLogs[0].commence_time || gameLogs[1].commence_time)}</Typography>
                        <Typography variant={typographyVariant}> {renderFormattedTime(gameLogs[0].commence_time || gameLogs[1].commence_time)}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Container>
      </Main>
    </Box>
  );
};

export default MLBprops;