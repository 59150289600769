import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from 'components/Container';
import Main from 'layouts/Main';
import { renderFormattedTime, renderFormattedDate, groupLogs, groupGameLogs, formatTimeAgo } from 'utils/utils';
import { Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow  } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const MLBspread = () => {
  const [logs, setLogs] = useState([]);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const typographyVariant = isMd ? 'body2' : 'subtitle1';

  useEffect(() => {
    fetch('/api/mlbspreadlog')
      .then(res => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then(data => {
        data.sort((a, b) => new Date(a.commence_time) - new Date(b.commence_time));
        setLogs(data);  // Update state with the fetched and sorted data
      })
      .catch(error => console.error('Error:', error));
  }, []); // Empty dependence

  const groupedLogs = groupLogs(logs);
  const firstGroup = groupedLogs[0];
  // const firstBookmaker = firstGroup && firstGroup[0] ? firstGroup[0].bookmaker : null;
  // const secondGroup = groupedLogs[1];
  // const secondBookmaker = secondGroup && secondGroup[1] ? secondGroup[1].bookmaker : null;
  const timeSinceUpdate = firstGroup && firstGroup[0] ? formatTimeAgo(firstGroup[0].last_api_update) : null;
  const headerStyling = { align: 'right', style: { verticalAlign: 'top' } };


  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <Main bgcolor={'background.paper'}>
        <Container>
          <div>
            <header>
              <h1>Upcoming MLB game spreads</h1>
              <p><i>Lines as of {timeSinceUpdate}, refresh every 12.</i></p>
              <p>*Current Expected Values are for illustration purposes. This functionality is being created and will be released soon.</p>
            </header>
          </div>
          <TableContainer component={Paper} sx={{ mx: 'auto', maxWidth: 950 }}>
            <Table sx={{ minWidth: 400}} aria-label="mlb-spread-table">
              <TableHead sx={{ bgcolor: 'grey.200' }}>
                <TableRow>
                  <TableCell><strong>Game</strong></TableCell>
                  <TableCell {...headerStyling}><strong>Spread</strong></TableCell>
                  <TableCell {...headerStyling}><strong>DraftKings</strong></TableCell>
                  <TableCell {...headerStyling}><strong>DraftKings<br />EV*</strong></TableCell>
                  <TableCell {...headerStyling}><strong>FanDuel</strong></TableCell>
                  <TableCell {...headerStyling}><strong>FanDuel<br />EV*</strong></TableCell>
                  <TableCell {...headerStyling}><strong>First Pitch</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupGameLogs(logs).map((gameLogs, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <Typography variant={typographyVariant}>{gameLogs[0]?.away_team}</Typography>
                      <Typography variant={typographyVariant}>{gameLogs[0]?.home_team}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant={typographyVariant}>{gameLogs[0].away_team_spread > 0 ? '+' : ''}{gameLogs[0].away_team_spread || gameLogs[1].away_team_spread > 0 ? '' : ''}{gameLogs[1].away_team_spread}</Typography>
                      <Typography variant={typographyVariant}>{gameLogs[0].home_team_spread > 0 ? '+' : ''}{gameLogs[0].home_team_spread || gameLogs[1].home_team_spread > 0 ? '' : ''}{gameLogs[1].home_team_spread}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant={typographyVariant}>{gameLogs[0].away_team_price > 0 ? '+' : ''}{gameLogs[0].away_team_price}</Typography>
                      <Typography variant={typographyVariant}>{gameLogs[0].home_team_price > 0 ? '+' : ''}{gameLogs[0].home_team_price}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant={typographyVariant}>{gameLogs[0].away_team_price !== '--' ? (Math.random() * 20 - 10).toFixed(1) + '%' : '--'}</Typography>
                      <Typography variant={typographyVariant}>{gameLogs[0].home_team_price !== '--' ? (Math.random() * 20 - 10).toFixed(1) + '%' : '--'}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant={typographyVariant}>{gameLogs[1].away_team_price > 0 ? '+' : ''}{gameLogs[1].away_team_price}</Typography>
                      <Typography variant={typographyVariant}>{gameLogs[1].home_team_price > 0 ? '+' : ''}{gameLogs[1].home_team_price}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant={typographyVariant}>{gameLogs[1].away_team_price !== '--' ? (Math.random() * 20 - 10).toFixed(1) + '%' : '--'}</Typography>
                      <Typography variant={typographyVariant}>{gameLogs[1].home_team_price !== '--' ? (Math.random() * 20 - 10).toFixed(1) + '%' : '--'}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant={typographyVariant}>{renderFormattedDate(gameLogs[0].commence_time || gameLogs[1].commence_time)}</Typography>
                      <Typography variant={typographyVariant}>{renderFormattedTime(gameLogs[0].commence_time || gameLogs[1].commence_time)}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Main>
    </Box>
  );
};

export default MLBspread;
