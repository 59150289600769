import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import { useTheme } from '@mui/material/styles';
import Logo from 'assets/logo.png';

import NavItem from './components/NavItem';

const SidebarNav = ({ pages }) => {
  // const theme = useTheme();
  // const { mode } = theme.palette;

  const {
    // offerings: offeringsPages,
    mlb: mlbPages,
    // nhl: nhlPages,
    mlbProps: mlbPropsPages,
    ncaaf: ncaafPages,
    // company: companyPages,
    // account: accountPages,
    // portfolio: portfolioPages,
    // blog: blogPages,
  } = pages;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component="a"
          href="/"
          title="+EV Bets"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={'img'}
            src={Logo}
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        {/* <Box>
          <NavItem title={'+EV Bets'} items={offeringsPages} />
        </Box> */}
        <Box>
          <NavItem title={'MLB'} items={mlbPages} />
        </Box>
        <Box>
          <NavItem title={'NCAA Foootball'} items={ncaafPages} />
        </Box>
        <Box>
          <NavItem title={'Props'} items={mlbPropsPages} />
        </Box>
        {/* <Box>
          <NavItem title={'NHL'} items={nhlPages} />
        </Box> */}
        {/* <Box>
          <NavItem title={'Pages'} items={secondaryPages} />
        </Box>
        <Box>
          <NavItem title={'Account'} items={accountPages} />
        </Box>
        <Box>
          <NavItem title={'Blog'} items={blogPages} />
        </Box>
        <Box>
          <NavItem title={'Portfolio'} items={portfolioPages} />
        </Box> */}
        {/* <Box marginTop={2}>
          <Button
            size={'large'}
            variant="outlined"
            fullWidth
            component="a"
            href="https://thefront.maccarianagency.com/docs/introduction"
            target={'blank'}
          >
            Documentation
          </Button>
        </Box> */}
        <Box marginTop={1}>
          <Button
            size={'large'}
            variant="contained"
            color="primary"
            fullWidth
            component="a"
            // target="blank"
            href="/sign-up"
          >
            Sign Up
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
