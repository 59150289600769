import React from 'react';

import {
  Home,
  // NHL,
  // MLB,
  NHLmoneyline,
  NHLspread,
  NHLtotal,
  MLBmoneyline,
  MLBspread,
  MLBtotal,
  MLBprops,
  NCAAFmoneyline,
  NCAAFspread,
  NCAAFtotal,
  SignUp,
} from 'pages';

const routes = [
  {
    path: '/',
    renderer: (params = {}) => <Home {...params} />,
  },
  {
    path: '/sign-up',
    renderer: (params = {}) => <SignUp {...params} />,
  },
  // {
  //   path: '/mlb',
  //   renderer: (params = {}) => <MLB {...params} />,
  // },
  {
    path: '/nhl/moneyline',
    renderer: (params = {}) => <NHLmoneyline {...params} />,
  },
  {
    path: '/nhl/spread',
    renderer: (params = {}) => <NHLspread {...params} />,
  },
  {
    path: '/nhl/total',
    renderer: (params = {}) => <NHLtotal {...params} />,
  },
  {
    path: '/mlb/moneyline',
    renderer: (params = {}) => <MLBmoneyline {...params} />,
  },
  {
    path: '/mlb/spread',
    renderer: (params = {}) => <MLBspread {...params} />,
  },
  {
    path: '/mlb/total',
    renderer: (params = {}) => <MLBtotal {...params} />,
  },
  {
    path: '/mlb/props',
    renderer: (params = {}) => <MLBprops {...params} />,
  },
  {
    path: '/ncaaf/moneyline',
    renderer: (params = {}) => <NCAAFmoneyline {...params} />,
  },
  {
    path: '/ncaaf/spread',
    renderer: (params = {}) => <NCAAFspread {...params} />,
  },
  {
    path: '/ncaaf/total',
    renderer: (params = {}) => <NCAAFtotal {...params} />,
  },
];

export default routes;