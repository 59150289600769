/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ExciteImage from 'assets/homepageexcites.png';

const mock = [
  {
    title: 68,
    subtitle:
      'Our historical win rate across all bet types. We\'re getting good over the years',
    suffix: '%',
  },
  {
    title: 24,
    subtitle:
      'Amount of markets we identify positive EV on. Moneylines to props across leagues',
    suffix: '+',
  },
  {
    title: 1,
    subtitle:
      'Still the lonliest number',
    suffix: '',
  },
];

const Features = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [viewPortEntered, setViewPortEntered] = useState(false);
  const setViewPortVisibility = (isVisible) => {
    if (viewPortEntered) {
      return;
    }

    setViewPortEntered(isVisible);
  };

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column-reverse'}>
        <Grid item xs={12} md={6}>
          <Box marginBottom={4}>
            <Typography sx={{ fontWeight: 700 }} variant={'h4'} gutterBottom>
              Sports betting excitement without the time suck
            </Typography>
            <Typography variant={'h6'} component={'p'} color={'text.secondary'}>
              Whether you’re analyzing odds for a big game, tracking player
              performance for parlays, or just looking for the next value bet, +EV Bets equips
              you with the insights you need to bet smart.
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {mock.map((item, i) => (
              <Grid key={i} item xs={12} md={4}>
                <Typography variant="h4" color={'primary'} gutterBottom>
                  <VisibilitySensor
                    onChange={(isVisible) => setViewPortVisibility(isVisible)}
                    delayedCall
                  >
                    <CountUp
                      duration={2}
                      end={viewPortEntered ? item.title : 0}
                      start={0}
                      suffix={item.suffix}
                    />
                  </VisibilitySensor>
                </Typography>
                <Typography color="text.secondary" component="p">
                  {item.subtitle}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
          sx={{
            display: { xs: 'flex', md: 'flex' },
          }}
        >
          <Box component={Card} boxShadow={4} height={1} width={1}>
            <Box
              component={CardMedia}
              height={1}
              width={1}
              minHeight={400}
              image={ExciteImage}
              sx={{
                backgroundPosition: '25% 25%', // Adjust this value to change the position of the image
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Features;
