/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import { Box, Card, Button, CardContent, Container, FormControl, OutlinedInput, Typography, FormHelperText, useMediaQuery } from '@mui/material';

const NewsletterWithCard = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSuccessMessage('');
    if (email === '') {
      setError('Please enter an email address');
    } else if (!validateEmail(email)) {
      setError('Please enter a valid email address');
    } else {
      setError('');
      fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            setError(data.error);
          } else {
            setSuccessMessage(data.message);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          setError('An error occurred while subscribing. Please try again later.');
        });
      // Submit the form
    }
  };

  return (
    <Container>
      <Box component={Card} boxShadow={3} paddingY={3}>
        <CardContent>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Box marginBottom={4}>
              <Typography
                variant="h4"
                align={'center'}
                gutterBottom
                sx={{ fontWeight: 700 }}
              >
                Let us email you
              </Typography>
              <Typography
                variant="h6"
                align={'center'}
                color={'text.secondary'}
              >
                We'll keep you in the loop about our latest news and updates.
              </Typography>
            </Box>
            <Box
              component="form"
              onSubmit={handleSubmit}
              width={1}
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'flex-start' }}
              justifyContent={'center'}
            >
              <FormControl
                error={Boolean(error)}
                fullWidth
                variant="outlined"
                sx={{ maxWidth: { xs: 1, sm: 400 }, width: 1 }}
              >
                <OutlinedInput
                  placeholder="Enter your email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
                {error && <FormHelperText>{error}</FormHelperText>}
                {successMessage && (
                  <Typography
                    color="primary"
                    sx={{
                      marginTop: 2, // adds a top margin
                      // fontSize: '1.2rem', // sets the font size
                      fontWeight: 'bold', // makes the text bold
                      color: 'green',
                      textAlign: 'left',
                    }}
                  >
                    {successMessage}
                  </Typography>
                )}
              </FormControl>
              <Box
                component={Button}
                variant="contained"
                color="primary"
                size="large"
                fullWidth={isSm ? false : true}
                marginTop={{ xs: 2, sm: 0 }}
                marginLeft={{ sm: 2 }}
                height={54}
                endIcon={<SendIcon />}
                type="submit"
              >
                Subscribe
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Box>
    </Container>
  );
};

export default NewsletterWithCard;
