/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import SportsIcon from '@mui/icons-material/Sports';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

const mock = [
  {
    title: 'Smart Insights for Efficient Betting',
    subtitle:
      'Your time is precious. That’s why we deliver quick, data-driven recommendations on the most promising wagers.',
    icon: <SportsIcon />,
  },
  {
    title: 'User-Friendly Interface for Busy Bettors',
    subtitle:
      'Our intuitive design ensures you can find the best betting options fast. You’re always just moments away from identifying your next potential win.',
    icon: <ChildFriendlyIcon />,
  },
  {
    title: 'Tailored Analytics for the Adulting Bettor',
    subtitle:
      'We cater to the parents looking for an edge. Our platform is your shortcut to smart betting, offering a clear view of profitable bets.',
    icon: <EmojiEventsIcon />,
  },
];

const Services = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4}>
        <Box marginBottom={2}>
          <Typography
            variant="h4"
            color="text.primary"
            align={'center'}
            gutterBottom
            sx={{
              fontWeight: 700,
            }}
          >
            Win More, Research Less with +EV Bets
          </Typography>
          <Typography
            variant="h6"
            component="p"
            color="text.secondary"
            sx={{ fontWeight: 400 }}
            align={'center'}
          >
            Our platform uses predictive analytics to identify bets with the best value, so you can enjoy the game without catching flack.
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box width={1} height={1}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
              >
                <Box
                  component={Avatar}
                  width={60}
                  height={60}
                  marginBottom={2}
                  bgcolor={alpha(theme.palette.primary.main, 0.1)}
                  color={theme.palette.primary.main}
                >
                  {item.icon}
                </Box>
                <Typography
                  variant={'h6'}
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                  align={'center'}
                >
                  {item.title}
                </Typography>
                <Typography align={'center'} color="text.secondary">
                  {item.subtitle}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Services;
