import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
// import HomePage from './pages/home';
// import SpLogsPage from './pages/splog';
import Routes from './Routes';
import Page from './components/Page';
// import './App.css';


const App = () => {
  return (
    <Page>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Page>
  );
};

export default App;

{/* <Page>
  <Router>
    <Routes>
      <Route path="/splog" element={<SpLogsPage />} />
      <Route path="/" element={<HomePage />} />
    </Routes>
  </Router>
</Page> */}


{/* <Page>
  <BrowserRouter>
    <Routes />
  </BrowserRouter>
</Page> */}
